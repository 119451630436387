body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-btn-toolbar {
  background: #fff;
  color: rgb(143, 90, 134);
  border: 2px solid rgb(248, 244, 247);
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: uppercase;
}

/* .my-button{
  background: #fff;
  color: rgb(3, 2, 2);
  border: 2px solid rgb(252, 245, 251);
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: uppercase;
} */

.Card_Img {
  width: 100%;
  height: 15vw;
  object-fit: cover;
}

.Card {
  width: 100%;
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}